import { useEffect, useState } from "react";
import { Container, Modal, Spinner } from "react-bootstrap"
import axios from "axios";
import closeIcon from '../../assets/images/glyph-close-gray.svg';
import phoneIcon from '../../assets/images/phone.svg';
import userIcon from '../../assets/images/user.svg';
import pencil from '../../assets/images/pencil-green.svg';
import styled from 'styled-components';
import clinicIcon from '../../assets/images/clinic.svg';
import locationIcon from '../../assets/images/location.svg';
import relationIcon from '../../assets/images/relation.svg';
import PhoneInput, { isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import { BodyContainer, BodyRow, BodyRowItem, TitleRow } from "./StyleComponents";
import { Input } from "../Input";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const CustomPhoneInput = styled(PhoneInput)`
    width: 100%;
    font-size: 12px;
    font-family: 'Hauora';
    color: var(--dark-text-color);
    font-weight: 400;
    background: white;
    .PhoneInputCountry{
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        padding: 7px;
    }
    input{
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        padding: 9px;
        transition: border 0.15s linear, box-shadow 0.15s linear;
        width:100%;
        margin-top:2px;
        margin-bottom:2px;
    }
`;
const SubText = styled.p`
font-family: 'Hauora';
font-size: 12px;
font-weight: 600;
`;

const EmergencyContact = ({ show, close, user }) => {
    const [userInfo, setUserInfo] = useState({
        mainEmergencyContact: {
            name: '', phone: '', relation: ''
        },
        outOfTownEmergencyContact: {
            name: '', phone: ''
        },
        physicianEmergencyContact: {
            company: '', name: '', phone: '', address: ''
        },
        pharmacyEmergencyContact: {
            name: '', phone: '', address: ''
        },
        allergies: [],
        religiousOrganization: {
            name: ''
        }
    });
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get('/api/v2/users_emergency_contact/' + user._id);
            setUserInfo({
                mainEmergencyContact: {
                    name: payload.mainEmergencyContact?.name || '',
                    phone: payload.mainEmergencyContact?.phone || '',
                    relation: payload.mainEmergencyContact?.relation || '',
                },
                outOfTownEmergencyContact: {
                    name: payload.outOfTownEmergencyContact?.name || '',
                    phone: payload.outOfTownEmergencyContact?.phone || '',
                },
                physicianEmergencyContact: {
                    company: payload.physicianEmergencyContact?.company || '',
                    name: payload.physicianEmergencyContact?.name || '',
                    phone: payload.physicianEmergencyContact?.phone || '',
                    address: payload.physicianEmergencyContact?.address || '',
                },
                pharmacyEmergencyContact: {
                    name: payload.pharmacyEmergencyContact?.name || '',
                    phone: payload.pharmacyEmergencyContact?.phone || '',
                    address: payload.pharmacyEmergencyContact?.address || '',
                },
                allergies: payload.allergies || [{ name: '' }],
                religiousOrganization: {
                    name: payload.religiousOrganization?.name || ''
                }
            });
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getDetails ~ error:", error);
            setLoading(false);
        }
    }

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            const { err, tempErrors } = validate();
            if (!err) {
                setLoading(true);
                await axios.put('/api/v2/users_emergency_contact/' + user._id, userInfo);
                Swal.fire({
                    icon: 'success',
                    title: 'Contact details has successfully updated',
                    allowEnterKey: true,
                    allowEscapeKey: false,
                    confirmButtonColor: '#4FB973',
                    confirmButtonText: 'Close',
                    allowOutsideClick: false
                }).then(() => {
                    setLoading(false);
                    setIsEditing(false);
                });
            } else {
                toast.error(tempErrors);
            }
        } catch (error) {
            console.log("Error saving details:", error);
            setLoading(false);
        }
    };

    const validate = () => {
        let err = false;
        let tempErrors = '';
        console.log(userInfo);
        const { mainEmergencyContact, outOfTownEmergencyContact, physicianEmergencyContact, pharmacyEmergencyContact } = userInfo;
        if (mainEmergencyContact && mainEmergencyContact.phone && mainEmergencyContact.phone.length > 3 && !isValidPhoneNumber(mainEmergencyContact.phone)) {
            tempErrors = 'Invalid Main Emergency Phone number.';
            err = true;
        } else if (outOfTownEmergencyContact && outOfTownEmergencyContact.phone && outOfTownEmergencyContact.phone.length > 3 && !isValidPhoneNumber(outOfTownEmergencyContact.phone)) {
            tempErrors = 'Invalid Out of town Phone number.';
            err = true;
        } else if (physicianEmergencyContact && physicianEmergencyContact.phone && physicianEmergencyContact.phone.length > 3 && !isValidPhoneNumber(physicianEmergencyContact.phone)) {
            tempErrors = 'Invalid Physician Phone number.';
            err = true;
        } else if (pharmacyEmergencyContact && pharmacyEmergencyContact.phone && pharmacyEmergencyContact.phone.length > 3 && !isValidPhoneNumber(pharmacyEmergencyContact.phone)) {
            tempErrors = 'Invalid Pharmacy Phone number.';
            err = true;
        } else {
            tempErrors = '';
            err = false;
        };
        return { err, tempErrors };
    };

    return (
        <Modal
            centered
            animation={true}
            backdrop="static"
            keyboard={false}
            size='lg'
            contentClassName="p-3"
            show={show}>
            <Modal.Header>
                <Modal.Title className="w-100">
                    <div className="d-flex justify-content-between align-items-center">
                        <span><b>Emergency Contact</b></span>
                        <div className="d-flex align-items-center">
                            {isEditing ? (
                                <button className="btn btn-success" onClick={handleSave}>Save</button>
                            ) : (
                                <div className="flex items-center cursor-pointer mr-2" onClick={handleEdit}>
                                    <SubText className="text-success mb-0">
                                        <img src={pencil} alt="Edit Arrow" className="mr-2" />
                                        Edit Contact details</SubText>
                                </div>
                            )}
                            <span onClick={() => close(false)} className="cursor-pointer ms-2 mx-3">
                                <img src={closeIcon} alt="" />
                            </span>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-auto mb-3">
                {loading ?
                    <Container className='text-center my-3'>
                        <Spinner animation="border" variant="success" />
                    </Container>
                    :
                    <div style={{ maxHeight: '70vh', }}>
                        <TitleRow>Main Contact</TitleRow>
                        <BodyContainer>
                            <BodyRow>
                                <BodyRowItem><img src={userIcon} alt="" className="mr-2" />Name </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <Input
                                            type='text'
                                            onChange={(e) => setUserInfo({ ...userInfo, mainEmergencyContact: { ...userInfo.mainEmergencyContact, name: e.target.value } })}
                                            value={userInfo.mainEmergencyContact.name}
                                            placeholder='Name'
                                        />
                                    ) : (
                                        userInfo.mainEmergencyContact.name || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={relationIcon} alt="" className="mr-2" />Relationship </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <Input
                                            type='text'
                                            onChange={(e) => setUserInfo({ ...userInfo, mainEmergencyContact: { ...userInfo.mainEmergencyContact, relation: e.target.value } })}
                                            value={userInfo.mainEmergencyContact.relation}
                                            placeholder='Relationship'
                                        />
                                    ) : (
                                        userInfo.mainEmergencyContact.relation || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={phoneIcon} alt="" className="mr-2" />Phone </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <CustomPhoneInput
                                            countrySelectProps={{ unicodeFlags: true }}
                                            defaultCountry="US"
                                            smartCaret={true}
                                            countryCallingCodeEditable={false}
                                            international={true}
                                            autoComplete="off"
                                            placeholder="Enter phone number"
                                            onChange={(e) => {
                                                setUserInfo({
                                                    ...userInfo,
                                                    mainEmergencyContact: {
                                                        ...userInfo.mainEmergencyContact,
                                                        phone: e,
                                                    },
                                                });
                                            }}
                                            value={userInfo.mainEmergencyContact.phone}
                                        />
                                    ) : (
                                        userInfo.mainEmergencyContact.phone || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                        </BodyContainer>
                        <TitleRow className="mt-5">Out-of-town emergency Contact</TitleRow>
                        <BodyContainer>
                            <BodyRow>
                                <BodyRowItem><img src={userIcon} alt="" className="mr-2" />Name </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <Input
                                            type='text'
                                            onChange={(e) => setUserInfo({ ...userInfo, outOfTownEmergencyContact: { ...userInfo.outOfTownEmergencyContact, name: e.target.value } })}
                                            value={userInfo.outOfTownEmergencyContact.name}
                                            placeholder='Name'
                                        />
                                    ) : (
                                        userInfo.outOfTownEmergencyContact.name || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={phoneIcon} alt="" className="mr-2" />Phone </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <CustomPhoneInput
                                            countrySelectProps={{ unicodeFlags: true }}
                                            defaultCountry="US"
                                            smartCaret={true}
                                            countryCallingCodeEditable={false}
                                            international={true}
                                            autoComplete="off"
                                            placeholder="Enter phone number"
                                            onChange={(e) => {
                                                setUserInfo({
                                                    ...userInfo,
                                                    outOfTownEmergencyContact: {
                                                        ...userInfo.outOfTownEmergencyContact,
                                                        phone: e,
                                                    },
                                                });
                                            }}
                                            value={userInfo.outOfTownEmergencyContact.phone}
                                        />
                                    ) : (
                                        userInfo.outOfTownEmergencyContact.phone || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                        </BodyContainer>
                        <TitleRow className="mt-5">Physician Contact</TitleRow>
                        <BodyContainer>
                            <BodyRow>
                                <BodyRowItem><img src={clinicIcon} alt="" className="mr-2" />Company </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <Input
                                            type='text'
                                            onChange={(e) => setUserInfo({ ...userInfo, physicianEmergencyContact: { ...userInfo.physicianEmergencyContact, company: e.target.value } })}
                                            value={userInfo.physicianEmergencyContact.company}
                                            placeholder='Company Name'
                                        />
                                    ) : (
                                        userInfo.physicianEmergencyContact.company || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={userIcon} alt="" className="mr-2" />Name </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <Input
                                            type='text'
                                            onChange={(e) => setUserInfo({ ...userInfo, physicianEmergencyContact: { ...userInfo.physicianEmergencyContact, name: e.target.value } })}
                                            value={userInfo.physicianEmergencyContact.name}
                                            placeholder='Name'
                                        />
                                    ) : (
                                        userInfo.physicianEmergencyContact.name || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={phoneIcon} alt="" className="mr-2" />Phone </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <CustomPhoneInput
                                            countrySelectProps={{ unicodeFlags: true }}
                                            defaultCountry="US"
                                            smartCaret={true}
                                            countryCallingCodeEditable={false}
                                            international={true}
                                            autoComplete="off"
                                            placeholder="Enter phone number"
                                            onChange={(e) => {
                                                setUserInfo({
                                                    ...userInfo,
                                                    physicianEmergencyContact: {
                                                        ...userInfo.physicianEmergencyContact,
                                                        phone: e,
                                                    },
                                                });
                                            }}
                                            value={userInfo.physicianEmergencyContact.phone}
                                        />
                                    ) : (
                                        userInfo.physicianEmergencyContact.phone || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={locationIcon} alt="" className="mr-2" />Address </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <Input
                                            type='text'
                                            onChange={(e) => setUserInfo({ ...userInfo, physicianEmergencyContact: { ...userInfo.physicianEmergencyContact, address: e.target.value } })}
                                            value={userInfo.physicianEmergencyContact.address}
                                            placeholder='Address'
                                        />
                                    ) : (
                                        userInfo.physicianEmergencyContact.address || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                        </BodyContainer>
                        <TitleRow className="mt-5">Pharmacy</TitleRow>
                        <BodyContainer>
                            <BodyRow>
                                <BodyRowItem><img src={userIcon} alt="" className="mr-2" />Name </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <Input
                                            type='text'
                                            onChange={(e) => setUserInfo({ ...userInfo, pharmacyEmergencyContact: { ...userInfo.pharmacyEmergencyContact, name: e.target.value } })}
                                            value={userInfo.pharmacyEmergencyContact.name}
                                            placeholder='Name'
                                        />
                                    ) : (
                                        userInfo.pharmacyEmergencyContact.name || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={phoneIcon} alt="" className="mr-2" />Phone </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <CustomPhoneInput
                                            countrySelectProps={{ unicodeFlags: true }}
                                            defaultCountry="US"
                                            smartCaret={true}
                                            countryCallingCodeEditable={false}
                                            international={true}
                                            autoComplete="off"
                                            placeholder="Enter phone number"
                                            onChange={(e) => {
                                                setUserInfo({
                                                    ...userInfo,
                                                    pharmacyEmergencyContact: {
                                                        ...userInfo.pharmacyEmergencyContact,
                                                        phone: e,
                                                    },
                                                });
                                            }}
                                            value={userInfo.pharmacyEmergencyContact.phone}
                                        />
                                    ) : (
                                        userInfo.pharmacyEmergencyContact.phone || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={locationIcon} alt="" className="mr-2" />Address </BodyRowItem>
                                <BodyRowItem>
                                    {isEditing ? (
                                        <Input
                                            type='text'
                                            onChange={(e) => setUserInfo({ ...userInfo, pharmacyEmergencyContact: { ...userInfo.pharmacyEmergencyContact, address: e.target.value } })}
                                            value={userInfo.pharmacyEmergencyContact.address}
                                            placeholder='Address'
                                        />
                                    ) : (
                                        userInfo.pharmacyEmergencyContact.address || '--'
                                    )}
                                </BodyRowItem>
                            </BodyRow>
                        </BodyContainer>
                        <TitleRow className="mt-5">Allergies</TitleRow>
                        <BodyRow>
                            <BodyRowItem>Name</BodyRowItem>
                            <BodyRowItem>
                                {isEditing ? (
                                    <Input
                                        type='text'
                                        onChange={
                                            (e) => {
                                                const updatedAllergies = e.target.value.split(',').map((allergy) => ({ name: allergy.trim() }));
                                                setUserInfo({ ...userInfo, allergies: updatedAllergies });
                                            }
                                        }
                                        value={userInfo.allergies && Array.isArray(userInfo.allergies)
                                            ? userInfo.allergies.map((allergy) => allergy.name).join(', ')
                                            : ''}
                                        placeholder='Enter allergies separated by commas'
                                    />
                                ) : (
                                    userInfo.allergies && Array.isArray(userInfo.allergies) && userInfo.allergies.length > 0
                                        ? userInfo.allergies.map((allergy, index) => allergy.name || '--').join(', ')
                                        : '--'
                                )}
                            </BodyRowItem>
                        </BodyRow>
                        <TitleRow className="mt-5">Religious Organization</TitleRow>
                        <BodyRow>
                            <BodyRowItem>Name </BodyRowItem>
                            <BodyRowItem>
                                {isEditing ? (
                                    <Input
                                        type='text'
                                        onChange={(e) => setUserInfo({ ...userInfo, religiousOrganization: { ...userInfo.religiousOrganization, name: e.target.value } })}
                                        value={userInfo.religiousOrganization.name}
                                        placeholder='Name'
                                    />
                                ) : (
                                    userInfo.religiousOrganization.name || '--'
                                )}
                            </BodyRowItem>
                        </BodyRow>
                    </div>}
            </Modal.Body>
        </Modal>
    )
}

export default EmergencyContact;